import React from "react";
import "../../App.css";
import OurServices from "../OurServices";
import HeroSection from "../HeroSection";
import AboutUs from "../AboutUs";
import Footer from "../Footer";

function Home() {
  return (
    <>
      <HeroSection />
      <AboutUs />
      <OurServices />
      <Footer />
    </>
  );
}

export default Home;
