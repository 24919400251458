import React from "react";
import "./About.css";

function About() {
  return (
    <div className="about" id="aboutUs">
      <div className="container">
        <div className="image">
          <img src="images/about_us.png" alt="About Us" />
        </div>
        <div className="content">
          <h1>ABOUT US</h1>
          <span className="line"></span>
          <p>
            TecHuis is a software development studio located in Johannesburg,
            South Africa. We specialize in UI/UX design, mobile development, web
            development, SEO management, hosting and WhatsApp Chatbot. We work
            with businesses of all sizes to help them create and grow their
            digital presence. We believe that every project is unique, and we
            take the time to understand our clients' needs and goals. We work
            closely with our clients throughout the development process to
            ensure that their vision is realized. We are passionate about
            creating innovative and user-friendly software that makes a
            difference in the world. We are committed to helping our clients
            succeed, and we believe that great software is the key to success.
          </p>
        </div>
      </div>
    </div>
  );
}

export default About;
