import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { Link } from "react-scroll";
import "./Footer.css";

const Footer = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    // Get form elements
    const nameInput = form.current.querySelector("#user_name");
    const emailInput = form.current.querySelector("#user_email");
    const messageInput = form.current.querySelector("#message");

    // Check if any of the required fields are empty
    if (!nameInput.value || !emailInput.value || !messageInput.value) {
      alert("Please fill in all required fields.");
      return;
    }

    emailjs
      .sendForm(
        "service_vafv46r",
        "template_1ljuc8e",
        form.current,
        "hZMsHK6A3lZQfo7J_"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("message sent");
          form.current.reset();
          alert("Email sent successfully!"); // Display confirmation message
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="footer" id="footerID">
      <div className="container">
        <div>
          <form className="formContainer" ref={form} onSubmit={sendEmail}>
            <div className="form-group">
              <label htmlFor="user_name">Name</label>
              <input type="text" id="user_name" name="user_name" required />
            </div>
            <div className="form-group">
              <label htmlFor="user_email">Email</label>
              <input type="email" id="user_email" name="user_email" required />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea id="message" name="message" required></textarea>
            </div>
            <button type="submit" className="submit-button">
              Send
            </button>
          </form>
        </div>
        <div className="menu">
          <h2>Menu</h2>
          <ul className="footer-menu">
            <li>
              <Link to="aboutUs">About Us</Link>
            </li>
            <li>
              <Link to="ourServices">Our Services</Link>
            </li>
          </ul>
        </div>
        <div className="bottom">
          <div className="footer-logo">
            <Link to="/" className="social-logo">
              <img src="images/techuis-logo.png" alt="TecHuis Logo" />
            </Link>
          </div>
          <h2>TecHuis</h2>
          <span className="line"></span>
          <small className="website-rights">
            TecHuis © 2023 | All rights reserved
          </small>
        </div>
      </div>
    </div>
  );
};

export default Footer;
