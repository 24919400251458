import React from "react";
import "./Cards.css";
import CardItem from "./CardItem";

function Cards() {
  return (
    <div className="cards" id="ourServices">
      <h1>OUR SERVICES</h1>
      <span className="line"></span>
      <div className="cards__container">
        <div className="cards__wrapper">
          <ul className="cards__items">
            <CardItem src="images/ui-ux-design.png" text="UI/UX Design" />
            <CardItem
              src="images/mobile-development.png"
              text="Mobile Development"
            />
          </ul>
          <ul className="cards__items">
            <CardItem
              src="images/web-development.png"
              text="Website Development"
            />
            <CardItem
              src="images/server.png"
              text="Hosting & Domain Managment"
            />
            <CardItem src="images/seo-managment.png" text="SEO Managment" />
            <CardItem src="images/chatBot.png" text="WhatsApp Chatbot" />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
