import React from "react";
import { Link as ScrollLink } from "react-scroll";
import Typed from "react-typed";
import "./HeroSection.css";

function HeroSection() {
  return (
    <div className="hero-container" id="hero">
      <img src="images/hero.jpg" className="hero-cover" alt="Hero Cover" />
      <h1>A TRUSTED SOFTWARE DEVELOPMENT COMPANY</h1>
      <p>
        <Typed
          strings={["What are you waiting for?", "Contact us now!"]}
          typed={150}
          backSpeed={100}
          loop
        />
      </p>
      <div className="hero-btns">
        <ScrollLink to="footerID" smooth={true} duration={500} offset={-80}>
          <button className="talkbtn">Let's talk</button>
        </ScrollLink>
      </div>
    </div>
  );
}

export default HeroSection;
